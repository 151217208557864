import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/client/components/client-segment.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/client/components/http-access-fallback/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/client/components/render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.1.7_@babel+core@7.26.9_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react_39952fd8e59eea11fad91c0f4e242e16/node_modules/next/dist/lib/metadata/metadata-boundary.js");
